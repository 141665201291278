export enum PlatformCode {
  VxSpot = 'VX_SPOT',
  Spot = 'SPOT',
  Bnr = 'BNR',
  Mt4 = 'MT4',
  Cfd = 'CFD',
  Bundle = 'BUNDLE',
  Mt5 = 'MT5',
  MtBundle = 'MTBUNDLE',
  PROP = 'PROP',
}

export const CFD_PLATFORMS = [PlatformCode.Cfd];

export const CFD_LIKE_PLATFORMS = [PlatformCode.Cfd, PlatformCode.Bundle];

export const CFD_MT4_PLATFORMS = [
  PlatformCode.Cfd,
  PlatformCode.Mt4,
  PlatformCode.Mt5,
];

export const MT4_PLATFORMS = [
  PlatformCode.Mt4,
  PlatformCode.Mt5,
  PlatformCode.PROP,
];

export const RISK_MANAGEMENT_MT4_PLATFORMS = [PlatformCode.Mt4];

export const RISK_MANAGEMENT_PLATFORMS = [
  PlatformCode.Cfd,
  PlatformCode.Mt4,
  PlatformCode.Bundle,
];
