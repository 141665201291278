import * as rx from '@proftit/rxjs';
import { IIntervalService } from 'angular';
import template from './challenge-phase-extra-container.component.html';
import {
  Customer,
  TradingAccount,
  ChallengePhase,
} from '@proftit/crm.api.models.entities';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { TimeInterval } from '@proftit/constants.time';
import AccountsSyncForexService from '~/source/contact/common/services/accounts-sync-forex.service';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { HighlightEntityService } from '~/source/common/services/highlight-entity';

const SAMPLE_ACCOUNT_FOREX_STATS_INTERVAL = 10 * TimeInterval.Minute;

export class ChallengePhaseExtraContainerController {
  lifecycles = observeComponentLifecycles(this);
  customer: IElementRestNg<Customer>;
  account: IElementRestNg<TradingAccount>;
  phase: IElementRestNg<ChallengePhase>;
  statsCheck: any;

  account$ = observeShareCompChange<TradingAccount>(
    this.lifecycles.onChanges$,
    'account',
  );
  customer$ = observeShareCompChange<Customer>(
    this.lifecycles.onChanges$,
    'customer',
  );

  /*@ngInject*/
  constructor(
    readonly highlightEntityService: HighlightEntityService,
    readonly accountsSyncForexService: AccountsSyncForexService,
    readonly $interval: IIntervalService,
  ) {
    useStreams([this.account$, this.customer$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    useStreams([this.account$, this.customer$], this.lifecycles.onDestroy$);
    this.syncStats();
    this.statsCheck = this.$interval(
      () => this.syncStats(),
      SAMPLE_ACCOUNT_FOREX_STATS_INTERVAL,
    );
  }

  $onDestroy() {
    this.$interval.cancel(this.statsCheck);
  }

  $onChanges() {}

  syncStats() {
    if (!this.account || !this.customer) return;
    this.accountsSyncForexService
      .setConfig({
        suppressGrowl: true,
        suppressBlockUi: true,
      })
      .sync(this.account?.id, this.customer?.id);
  }

  streamAccountChanges() {
    return rx.pipe(() => this.account$, shareReplayRefOne())(null);
  }

  streamCustomerChanges() {
    return rx.pipe(() => this.customer$, shareReplayRefOne())(null);
  }
}

export const ChallengePhaseExtraContainerComponent = {
  template,
  controller: ChallengePhaseExtraContainerController,
  bindings: {
    customer: '<',
    account: '<',
    phase: '<',
  },
};
